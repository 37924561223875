import React from 'react'
import { Grid, Typography } from '@mui/material'
import 'typeface-inter';
// import FLogo from './Images/Flogo.png';
import FLogo from './Images/Rmlogo.jpg';
import { useMediaQuery,Button } from '@mui/material';
import { Link } from 'react-router-dom'
import Insta from './Images/Instam-unscreen.gif'
import linked from './Images/linkedin-unscreen.gif'
import { useNavigate } from 'react-router-dom';

export const Footer1 = () => {
	const mobile = useMediaQuery('(max-width:600px)');
	const navigate = useNavigate();
	const handleContact = () => {
		navigate('/contact')
	}
  return (
	  <Grid container lg={12} xs={12} sx={{ background: 'linear-gradient(to top, #053F5C 80%, transparent 20%)' }}>
		  <Grid container lg={10} xs={10} sx={{background:'#F6C35E',borderRadius:'26px',margin:'auto',padding:mobile?"32px 14px":'56px'}}>
			  <Grid container lg={8} xs={12} sx={{justifyContent:'center'}}>
				  <Grid item lg={11} xs={10}>
				  <Typography style={{color: '#053F5C',textAlign:mobile?"center":'start',
fontFamily: 'Alegreya',
fontSize: mobile?"26px":'56px',
fontStyle: 'normal',
fontWeight:mobile?"700": 600,
					  lineHeight: mobile?"28.6px":'61.6px', marginBottom: '20px'
					  }}>Connect With Us Today! </Typography>
					  </Grid>
				  <Grid item lg={11} xs={12}>
				  <Typography style={{color: '#053F5C',textAlign:'start',
fontFamily: 'Alegreya',
fontSize: mobile?"14px":'18px',
fontStyle: 'normal',
fontWeight: 400,
						  lineHeight: mobile ? "19.6px" : '28.8px', marginBottom: '20px'
					  }}>Sign up to enter your book as an author or join the debate by signing up as a{mobile ?'': <br />} reviewer.</Typography>
				  </Grid>
			  </Grid>
			  <Grid container lg={4} sx={{alignItems:'center',justifyContent:'center'}}>
			  <Button onClick={handleContact}sx={{ fontSize: mobile?"14px":'18px', fontWeight: '600', lineHeight: '32px', color: '#fff', backgroundColor: '#053F5C', textTransform: 'none', borderRadius: '12px', padding: '14px 30px', height: '42px', fontFamily: 'alegreya', '&:hover': { backgroundColor: '#005B9D' } }}>Contact Us</Button>
          	  
				  </Grid>
		  </Grid>
		  <Grid container lg={10} xs={11} sx={{ margin: 'auto',marginTop:'56px',marginBottom:'26px' }}>
			  
		 
		  <Grid container lg={12} xs={12} sx={{display:'flex',justifyContent:'space-between'}}>
			  <Grid item lg={4} xs={12}>
				  <img src={FLogo} style={{width: '128px',borderRadius:'12px',
height: '92px'}}/>
			  </Grid>
			  <Grid item lg={2} xs={12}>
				  <Typography style={{color: '#fff',textAlign:'start',
fontFamily: 'Alegreya',
fontSize: '14px',
fontStyle: 'normal',
fontWeight: 700,
lineHeight: '20px',marginBottom:'20px',marginTop:mobile?"27px":"" }}>Quicklinks</Typography>
				 
				 <Link to="/Home" style={{ textDecoration: 'none' }}>  <Typography style={{color: '#EFEFEF',textAlign:'start',
fontFamily: 'Alegreya',
fontSize: '14px',
fontStyle: 'normal',
fontWeight: 400,marginBottom:'20px',
						  lineHeight: '20px', cursor: 'pointer'
					  }}>Home</Typography></Link>
				<Link to="/Booklist" style={{ textDecoration: 'none' }}> 	  <Typography style={{color: '#EFEFEF',textAlign:'start',
fontFamily: 'Alegreya',
fontSize: '14px',
fontStyle: 'normal',
fontWeight: 400,
						  lineHeight: '20px',marginBottom:'20px', cursor: 'pointer'
					  }}>Books</Typography></Link>
					 <Link to="/about" style={{ textDecoration: 'none' }}>   <Typography style={{color: '#EFEFEF',textAlign:'start',
fontFamily: 'Alegreya',
fontSize: '14px',
fontStyle: 'normal',
fontWeight: 400,
						  lineHeight: '20px', marginBottom: '20px', cursor: 'pointer'
					  }}>About</Typography></Link>
					  <Link to="/contact" style={{ textDecoration: 'none' }}> 
				  <Typography style={{color: '#EFEFEF',textAlign:'start',
fontFamily: 'Alegreya',
fontSize: '14px',
fontStyle: 'normal',
fontWeight: 400,
lineHeight: '20px',marginBottom:'20px', cursor: 'pointer' }}>Contact</Typography></Link>
			  </Grid>
			  <Grid item lg={2} xs={12}>
				  
					  
				  <Typography style={{color: '#EFEFEF',textAlign:'start',
fontFamily: 'Alegreya',
fontSize: '14px',
fontStyle: 'normal',
fontWeight: 700,
						  lineHeight: '20px', marginBottom: '20px', cursor: 'pointer'
					  }}>Support</Typography>
					  <Link to="/terms" style={{ textDecoration: 'none' }}> 
					     <Typography style={{color: '#fff',textAlign:'start',
fontFamily: 'Alegreya',
fontSize: '14px',
fontStyle: 'normal',
fontWeight: 400,
							  lineHeight: '20px', marginBottom: '20px', cursor: 'pointer'
						  }}>Terms and Conditions</Typography>
					  </Link>
					  <Link to="/privacy" style={{ textDecoration: 'none' }}> 
				  <Typography style={{color: '#EFEFEF',textAlign:'start',
fontFamily: 'Alegreya',
fontSize: '14px',
fontStyle: 'normal',
fontWeight: 400,
							  lineHeight: '20px', marginBottom: '20px', cursor: 'pointer'
						  }}>Privacy Policy</Typography>
						  </Link>
				  </Grid>
				  <Grid item lg={4} xs={12} sx={{ display: 'flex',justifyContent:'start' }}>
					  <Grid item lg={12}>
					  <Typography style={{color: '#EFEFEF',textAlign:'start',
fontFamily: 'Alegreya',
fontSize: '14px',
fontStyle: 'normal',
fontWeight: 700,
						  lineHeight: '20px', cursor: 'pointer'
						  }}>Social Media</Typography>
						   <Grid item lg={12} sx={{display:'flex',justifyContent:'start',marginLeft:'-25px'}}>
						   <a href="https://www.instagram.com/reviewmybooks_/" target="_blank" rel="noopener noreferrer">
								  <img src={Insta} style={{ width: '80px', height: '65px' }} />
							  </a>
							  <a href="https://www.instagram.com/reviewmybooks_/" target="_blank" rel="noopener noreferrer">
								  
								  <img src={linked} style={{ width: '80px', height: '65px', marginLeft: '-16px' }} />
								  </a>  </Grid>
					  </Grid>
					 
					  
			  </Grid>
			  
				  	  
			  </Grid>
			  <Grid item lg={11.5} xs={12} style={{justifyContent:mobile?"start":'end',display:'flex'}}>
			  <Typography style={{color: '#6E6D7A',textAlign:'start',color:"#EFEFEF",
fontFamily: 'Alegreya',
fontSize: '14px',
fontStyle: 'normal',
fontWeight: 400,
lineHeight: '20px',marginBottom:'20px' }}>&copy; reviewmybook. All rights reserved.</Typography>
				  </Grid>
			  </Grid>
	</Grid>
  )
}