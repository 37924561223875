import React from 'react'
import { Button, Grid,useMediaQuery } from '@mui/material'
import Typography from '@mui/material/Typography';
import { TabPanel } from '@mui/lab';
import ArrowIcon from './ArrowIcon';
import Rarrow from './Images/Rarrow.png'
import {Helmet} from "react-helmet";
import Larrow from './Images/Larrow.png'
import { useNavigate } from 'react-router-dom';
import Header from './Header';
import { Footer } from './Footer';
import ArrowM from './ArrowIconM';
function UserJourney() {
  const mobile = useMediaQuery('(max-width:600px)');
	const navigate = useNavigate();
	const handleClick = (stepvalue) => {
		//console.log(stepvalue,'sl')
		const element = document.getElementById(stepvalue);
		if (element) {
		  element.scrollIntoView({ behavior: 'smooth' });
		}
	};
	const listItemStyle = {
		color: 'var(--Brand-Dark-Blue, #053F5C)',
		fontFeatureSettings: "'salt' on, 'clig' off, 'liga' off",
		fontFamily: 'Alegreya',
		fontSize:mobile?'14px': '20px',
		fontStyle: 'normal',
		fontWeight: 400,
		lineHeight:mobile?'21px': '30px', // 150%
		letterSpacing:mobile?'0px': '1px',
		marginBottom: '10px', 
        // Add some spacing between list items
	};
	const handleSignup = () => {
		navigate('/sign')
	}
	const handlelogin = () => {
		navigate('/login')
	}
  return (
	  <>
		  <Helmet>
  <title>Start Your Journey | ReviewMyBooks.com</title>
  <meta name="description" content="Start your book review journey with ReviewMyBooks.com. Learn how to get paid for reviewing books and join our platform today." />
</Helmet>

		  <Header/>
		 <Grid container lg={12} xs={12} sx={{ backgroundColor: '#F8F5F0', justifyContent: 'center' }}>
    <Grid container lg={10} xs={12} sx={{ margin: 'auto' }}>
        <Grid container lg={12} xs={12} sx={{justifyContent:'center'}}>
            <Typography sx={{ textAlign: 'center', fontSize: mobile ? '26px' : '56px', fontWeight: '700', fontFamily: 'Alegreya', color: '#053F5C', marginTop:mobile?'40px': '76px' }}>
                User Journey
            </Typography>
        </Grid>
        <Grid container lg={12} xs={12} sx={{justifyContent:mobile?'center':''}}>
            <Grid item lg={12} xs={10}>
                <Typography sx={{ fontSize: mobile ? '16px' : '24px', fontWeight: '700', lineHeight: '64px', fontFamily: 'Alegreya', textAlign:mobile?'start': 'center', marginTop: '20px', marginRight: mobile ? '0px' : '700px' }}>
                    THE STEPS:
                </Typography>
            </Grid>
        </Grid>
        <Grid container xs={12} sx={{ justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginTop:mobile?'20px': '40px' }}>
            <Grid item container sx={{ alignItems: 'center', justifyContent: 'center' }}>
                <Button onClick={(e) => handleClick('step1')} sx={{
                    color: '#EFEFEF',
                    fontFamily: 'Alegreya',
                    width: mobile ? '218px' : '386px',
                    height: mobile ? '39px' : '64px',
                    backgroundColor: '#053F5C',
                    borderRadius: mobile ? '8px' : '12px',
                    fontSize: mobile ? '14px' : '24px',
                    fontWeight: '400',
                    lineHeight: '64px',
                    textTransform: 'none',
                    '&:hover': {
                        backgroundColor: '#053F5C'
                    }
                }}>
                    Signing Up/ Logging In
                </Button>
                <img src={Rarrow} alt="Arrow Right" style={{
                    width: '50px',
                    height:mobile?'67px': 'auto',
                    marginLeft: '10px',
                    position: mobile ? 'absolute' : 'absolute',
                    marginLeft: mobile ? '252px' : '440px',
                    marginTop: mobile ? '38px' : '47px'
                }} />
            </Grid>
            <Grid item container sx={{ alignItems: 'center', justifyContent: 'center', marginTop: mobile ? '20px' : '20px' }}>
                <img src={Larrow} alt="Arrow Left" style={{
                    width: '50px',
                    height:mobile?'67px': 'auto',
                    marginLeft: '10px',
                    position: mobile ? 'absolute' : 'absolute',
                    marginRight: mobile ? '267px' : '445px',
                    marginTop: mobile ? '46px' : '56px'
                }} />
                <Button onClick={(e) => handleClick('step2')} sx={{
                    color: '#EFEFEF',
                    fontFamily: 'Alegreya',
                    width: mobile ? '218px' : '386px',
                    height: mobile ? '39px' : '64px',
                    backgroundColor: '#053F5C',
                    borderRadius: mobile ? '8px' : '12px',
                    fontWeight: '400',
                    lineHeight: '64px',
                    textTransform: 'none',
                    fontSize: mobile ? '14px' : '24px',
                    '&:hover': {
                        backgroundColor: '#053F5C'
                    }
                }}>
                    Browsing and selecting Books
                </Button>
            </Grid>
            <Grid item container sx={{ alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}>
                <Button onClick={(e) => handleClick('step3')} sx={{
                    color: '#EFEFEF',
                    fontFamily: 'Alegreya',
                    width: mobile ? '218px' : '386px',
                    height: mobile ? '39px' : '64px',
                    backgroundColor: '#053F5C',
                    borderRadius: mobile ? '8px' : '12px',
                    fontWeight: '400',
                    lineHeight: '64px',
                    textTransform: 'none',
                    fontSize: mobile ? '14px' : '24px',
                    '&:hover': {
                        backgroundColor: '#053F5C'
                    }
                }}>
                    Reviewing and submitting
                </Button>
                <img src={Rarrow} alt="Arrow Right" style={{
                    width: '50px',
                    height:mobile?'67px': 'auto',
                    marginLeft: '10px',
                    position: mobile ? 'absolute' : 'absolute',
                    marginLeft: mobile ? '262px' : '434px',
                    marginTop: mobile ? '48px' : '47px'
                }} />
            </Grid>
            <Grid item container sx={{ alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}>
                <img src={Larrow} alt="Arrow Left" style={{
                    width: '50px',
                    height:mobile?'67px': 'auto',
                    marginLeft: '10px',
                    position: mobile ? 'absolute' : 'absolute',
                    marginRight: mobile ? '273px' : '440px',
                    marginTop: mobile ? '60px' : '47px'
                }} />
                <Button onClick={(e) => handleClick('step4')} sx={{
                    color: '#EFEFEF',
                    fontFamily: 'Alegreya',
                    width: mobile ? '218px' : '386px',
                    height: mobile ? '39px' : '64px',
                    backgroundColor: '#053F5C',
                    borderRadius: mobile ? '8px' : '12px',
                    fontWeight: '400',
                    lineHeight: '64px',
                    textTransform: 'none',
                    fontSize: mobile ? '14px' : '24px',
                    '&:hover': {
                        backgroundColor: '#053F5C'
                    }
                }}>
                    Checkout Process
                </Button>
            </Grid>
            <Grid item container sx={{ alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}>
                <Button onClick={(e) => handleClick('step5')} sx={{
                    color: '#EFEFEF',
                    fontFamily: 'Alegreya',
                    width: mobile ? '218px' : '386px',
                    height: mobile ? '39px' : '64px',
                    backgroundColor: '#053F5C',
                    borderRadius: mobile ? '8px' : '12px',
                    fontWeight: '400',
                    lineHeight: '64px',
                    textTransform: 'none',
                    fontSize: mobile ? '14px' : '24px',
                    '&:hover': {
                        backgroundColor: '#053F5C'
                    }
                }}>
                    Reimbursement
                </Button>
            </Grid>
        </Grid>
    </Grid>
</Grid>

		  
		  <Grid container lg={12}  xs={12} style={{ justifyContent: 'center',backgroundColor: '#F8F5F0', }}>
			  <Grid item lg={11} xs={10}>
			  <Typography id='step1' style={{
				  color: 'var(--Brand-Dark-Blue, #030605)',
    fontFamily: 'Alegreya',
    fontSize: mobile?'16px':24,
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '64px',}}>STEP 1:</Typography>	  
		  </Grid>
			 	
			  <Grid container lg={9} xs={12}>
			 
				  <Grid container lg={6} xs={12} sx={{justifyContent:mobile?'center':''}}>
					  <Grid item lg={1.5}  xs={1.5} style={{alignItems:'center',display:'flex',marginBottom:'20px'}}>
						 {mobile?<ArrowM/>: <ArrowIcon />}
					  </Grid>
					  <Grid item lg={10.5} sx={{marginBottom:'20px'}}>
					  <Button  onClick={(e)=>handleClick('step1')} style={{ borderRadius: 12,
    background: '#B1DAEE',
   
    color: 'var(--Brand-Dark-Blue, #053F5C)',
    fontFamily: 'Alegreya',
    fontSize:mobile?'14px': '24px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '64px', // 266.667%
    fontFeatureSettings: "'clig' off, 'liga' off",textTransform:'capitalize',width:mobile?'218px': '386px',
height:mobile?'39px':'64px'
    }}>
				Signing Up/ Logging In		  
					  </Button>
					  </Grid>
					  <Grid item lg={12} sx={{marginBottom:'20px'}}>
					  <Button onClick={(e)=>handleClick('step2')} style={{ borderRadius: 12,
    background: '#053F5C',
   
    color: 'var(--Brand-Dark-Blue, #EFEFEF)',
    fontFamily: 'Alegreya',
    fontSize:mobile?'14px': '24px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '64px', // 266.667%
    fontFeatureSettings: "'clig' off, 'liga' off",textTransform:'capitalize',width:mobile?'218px': '386px',
height:mobile?'39px':'64px'
    }}>
				Browsing and selecting Books	  
					  </Button>
					  </Grid>
					  <Grid item lg={12} sx={{marginBottom:'20px'}}>
					  <Button  onClick={(e)=>handleClick('step3')} style={{ borderRadius: 12,
    background: '#053F5C',
   
    color: 'var(--Brand-Dark-Blue, #EFEFEF)',
    fontFamily: 'Alegreya',
    fontSize:mobile?'14px': '24px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '64px', // 266.667%
    fontFeatureSettings: "'clig' off, 'liga' off",textTransform:'capitalize',width:mobile?'218px': '386px',
height:mobile?'39px':'64px'
    }}>Reviewing and submitting		  
					  </Button>
					  </Grid>
					  <Grid item lg={12} sx={{marginBottom:'20px'}}>
					  <Button  onClick={(e)=>handleClick('step4')} style={{ borderRadius: 12,
    background: '#053F5C',
   
    color: 'var(--Brand-Dark-Blue, #EFEFEF)',
    fontFamily: 'Alegreya',
    fontSize:mobile?'14px': '24px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '64px', // 266.667%
    fontFeatureSettings: "'clig' off, 'liga' off",textTransform:'capitalize',width:mobile?'218px': '386px',
height:mobile?'39px':'64px'
    }}>
				Checkout Process	  
					  </Button>
					  </Grid>
					  <Grid item lg={12} sx={{marginBottom:'20px'}}>
					  <Button  onClick={(e)=>handleClick('step5')} style={{ borderRadius: 12,
    background: '#053F5C',
   
    color: 'var(--Brand-Dark-Blue, #EFEFEF)',
    fontFamily: 'Alegreya',
    fontSize:mobile?'14px': '24px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '64px', // 266.667%
    fontFeatureSettings: "'clig' off, 'liga' off",textTransform:'capitalize',width:mobile?'218px': '386px',
height:mobile?'39px':'64px'
    }}>
				Reimbursement	  
					  </Button>
					  </Grid>
				  </Grid>
				  <Grid container lg={6} xs={11}  sx={{padding:mobile?'23px':''}}>
				  <Typography variant="body1"  component="ul" style={{color: 'var(--Brand-Dark-Blue, #053F5C)',
    fontFeatureSettings: "'salt' on, 'clig' off, 'liga' off",
    fontFamily: 'Alegreya',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '30px', // 150%
    letterSpacing: '1px',}}>
        <li style={{color: 'var(--Brand-Dark-Blue, #053F5C)',
    fontFeatureSettings: "'salt' on, 'clig' off, 'liga' off",
    fontFamily: 'Alegreya',
    fontSize:mobile?'14px': '20px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight:mobile?'21px': '30px', // 150%
    letterSpacing: mobile?'0px':'1px',marginTop:mobile?'5px':''}}>Navigate to the website's homepage.</li>
        <li style={{color: 'var(--Brand-Dark-Blue, #053F5C)',
    fontFeatureSettings: "'salt' on, 'clig' off, 'liga' off",
    fontFamily: 'Alegreya',
    fontSize: mobile?'14px':'20px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight:mobile?'21px': '30px', // 150%
    letterSpacing:mobile?'0px': '1px',marginTop:mobile?'5px':''}}>Look for a "Login" or "Sign In" button located at the top right corner.</li>
        <li style={{color: 'var(--Brand-Dark-Blue, #053F5C)',
    fontFeatureSettings: "'salt' on, 'clig' off, 'liga' off",
    fontFamily: 'Alegreya',
    fontSize:mobile?'14px': '20px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight:mobile?'21px': '30px', // 150%
    letterSpacing: mobile?'0px':'1px',marginTop:mobile?'5px':''}}>Enter your name or email address and password in the designated fields.</li>
        <li style={{color: 'var(--Brand-Dark-Blue, #053F5C)',
    fontFeatureSettings: "'salt' on, 'clig' off, 'liga' off",
    fontFamily: 'Alegreya',
    fontSize:mobile?'14px': '20px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight:mobile?'21px': '30px', // 150%
    letterSpacing:mobile?'0px': '1px',marginTop:mobile?'5px':''}}>Complete any additional security steps, such as entering a verification code sent to your email or phone.</li>
					  </Typography>
					  <Grid item lg={12} xs={12}  sx={{display:'flex',justifyContent:'space-between',marginTop:mobile?'25px':''}}>
					  <Grid item lg={5.5} xs={12}  sx={{justifyContent:'end',display:'flex'}}>
					  <Button onClick={handleSignup} style={{borderRadius: '6px',
    border: '2px solid var(--Brand-Dark-Blue, #053F5C)',
    display: 'inline-flex',
    height: '42px',
    padding: '14px 24px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    flexShrink: 0,
    fontFamily: 'Alegreya', // Example font family
    fontSize:mobile?'14px': '16px', // Example font size
    fontWeight:mobile?700: 500, // Example font weight
    color: '#053F5C', // Example text color
    backgroundColor: 'transparent', // Transparent background
    cursor: 'pointer',
    textDecoration: 'none',
    textTransform:'none',
    width:mobile?'106px':'',
    marginRight:mobile?'1px':'',
    outline: 'none',
    transition: 'background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease',}}>Sign Up</Button>  
					  </Grid>
					  <Grid item lg={5.5} xs={12}  sx={{justifyContent:'start',display:'flex'}}>
							  <Button onClick={handlelogin} style={{borderRadius: '6px',
    border: '2px solid var(--Brand-Dark-Blue, #053F5C)',
    display: 'inline-flex',
    height: '42px',
    padding: '14px 24px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    flexShrink: 0,
    width:mobile?'106px':'',
    fontFamily: 'Alegreya', // Example font family
    fontSize: mobile?'14px':'16px', // Example font size
    fontWeight:mobile?700: 500, // Example font weight
    color: '#053F5C', // Example text color
    backgroundColor: 'transparent', // Transparent background
    cursor: 'pointer',
    textDecoration: 'none',
    textTransform:'none',
    outline: 'none',
    marginLeft:mobile?'13px':'',
    transition: 'background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease',}}>Login</Button>  
						  </Grid>
						  </Grid>
					 
				  </Grid>
			  </Grid>
			  <Grid item lg={11} xs={10} >
			  <Typography id='step2' style={{
				  color: 'var(--Brand-Dark-Blue, #030605)',
    fontFamily: 'Alegreya',
    fontSize:mobile?'16px':24,
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '64px',}}>STEP 2:</Typography>	  
		  </Grid>
			  <Grid container lg={9} xs={12}>
				  
				  <Grid container lg={6} xs={12} sx={{justifyContent:mobile?'center':''}}>
					  <Grid item lg={12} xs={12} sx={{marginBottom:'20px',textAlign:mobile?'center':''}}>
					  <Button  onClick={(e)=>handleClick('step1')} style={{ borderRadius: 12,
    background: '#053F5C',
   
    color: 'var(--Brand-Dark-Blue, #EFEFEF)',
    fontFamily: 'Alegreya',
    fontSize:mobile?'14px': 24,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '64px', // 266.667%
    fontFeatureSettings: "'clig' off, 'liga' off",textTransform:'capitalize',width:mobile?'218px': '386px',
height:mobile?'39px': '64px'
    }}>
				Signing Up/ Logging In		  
					  </Button>
					  </Grid>
					  <Grid item lg={1.5} xs={1.5} style={{alignItems:'center',display:'flex',marginBottom:'20px'}}>
            {mobile?<ArrowM/>: <ArrowIcon />}
					  </Grid>  
					  <Grid item lg={10.5}  sx={{marginBottom:'20px'}}>
					  <Button   onClick={(e)=>handleClick('step2')}  style={{ borderRadius: 12,
    background: '#B1DAEE',
   
    color: 'var(--Brand-Dark-Blue, #053F5C)',
    fontFamily: 'Alegreya',
    fontSize:mobile?'14px': 24,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '64px', // 266.667%
    fontFeatureSettings: "'clig' off, 'liga' off",textTransform:'capitalize',width:mobile?'218px': '386px',
height:mobile?'39px': '64px'
    }}>
				Browsing and selecting Books	  
					  </Button>
					  </Grid>
					  <Grid item lg={12} sx={{marginBottom:'20px'}}>
					  <Button  onClick={(e)=>handleClick('step3')} style={{ borderRadius: 12,
    background: '#053F5C',
   
    color: 'var(--Brand-Dark-Blue, #EFEFEF)',
    fontFamily: 'Alegreya',
    fontSize: mobile?'14px':24,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '64px', // 266.667%
    fontFeatureSettings: "'clig' off, 'liga' off",textTransform:'capitalize',width:mobile?'218px': '386px',
height:mobile?'39px': '64px'
    }}>Reviewing and submitting		  
					  </Button>
					  </Grid>
					  <Grid item lg={12} sx={{marginBottom:'20px'}}>
					  <Button  onClick={(e)=>handleClick('step4')} style={{ borderRadius: 12,
    background: '#053F5C',
   
    color: 'var(--Brand-Dark-Blue, #EFEFEF)',
    fontFamily: 'Alegreya',
    fontSize:mobile?'14px': 24,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '64px', // 266.667%
    fontFeatureSettings: "'clig' off, 'liga' off",textTransform:'capitalize',width:mobile?'218px': '386px',
height:mobile?'39px': '64px'
    }}>
				Checkout Process	  
					  </Button>
					  </Grid>
					  <Grid item lg={12} sx={{marginBottom:'20px'}}>
					  <Button  onClick={(e)=>handleClick('step5')} style={{ borderRadius: 12,
    background: '#053F5C',
   
    color: 'var(--Brand-Dark-Blue, #EFEFEF)',
    fontFamily: 'Alegreya',
    fontSize:mobile?'14px': 24,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '64px', // 266.667%
    fontFeatureSettings: "'clig' off, 'liga' off",textTransform:'capitalize',width: mobile?'218px':'386px',
height:mobile?'39px': '64px'
    }}>
				Reimbursement	  
					  </Button>
					  </Grid>
				  </Grid>
				  <Grid container lg={6} sx={{padding:mobile?'23px':''}}>
				  <Typography variant="body1" component="ul">
        <li style={listItemStyle}>Once logged in, navigate to the section dedicated to books.</li>
        <li style={listItemStyle}>Read the book's description, author information, and click "Add to List".</li>
        <li style={listItemStyle}>If you decide to purchase the book, look for an "Add to List" and then “Buy Now” button.</li>
        <li style={listItemStyle}>Click on "Buy Now" to add the book to your shopping cart.</li>
        <li style={listItemStyle}>Navigate to your My List, usually accessible through an icon or link indicating the number of items.</li>
        <li style={listItemStyle}>Review the order summary to confirm the details of your purchase.</li>
      </Typography>
				  </Grid>
			  </Grid>
			 

				  <Grid item lg={11} xs={10}>
			  <Typography id='step3' style={{
				  color: 'var(--Brand-Dark-Blue, #030605)',
    fontFamily: 'Alegreya',
    fontSize:mobile?'16px': 24,
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '64px',}}>STEP 3:</Typography>	  
			  </Grid>
			  <Grid container lg={9} xs={12}>
				  <Grid container lg={6} xs={12} sx={{justifyContent:mobile?'center':''}}>
					  <Grid item lg={12}  xs={12} sx={{marginBottom:'20px',textAlign:mobile?'center':''}}>
					  <Button  onClick={(e)=>handleClick('step1')} style={{ borderRadius: 12,
    background: '#053F5C',
   
    color: 'var(--Brand-Dark-Blue, #EFEFEF)',
    fontFamily: 'Alegreya',
    fontSize:mobile?'14px': 24,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '64px', // 266.667%
    fontFeatureSettings: "'clig' off, 'liga' off",textTransform:'capitalize',width:mobile?'218px': '386px',
height:mobile?'39px': '64px'
    }}>
				Signing Up/ Logging In		  
					  </Button>
					  </Grid>
					  <Grid item lg={12} xs={12} sx={{marginBottom:'20px',textAlign:mobile?'center':''}}>
					  <Button   onClick={(e)=>handleClick('step2')}  style={{ borderRadius: 12,
 background: '#053F5C',
   
 color: 'var(--Brand-Dark-Blue, #EFEFEF)',
    fontFamily: 'Alegreya',
    fontSize:mobile?'14px': 24,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '64px', // 266.667%
    fontFeatureSettings: "'clig' off, 'liga' off",textTransform:'capitalize',width:mobile?'218px': '386px',
height:mobile?'39px': '64px'
    }}>
				Browsing and selecting Books	  
					  </Button>
					  </Grid>
					  <Grid item lg={1.5} xs={1.5} style={{alignItems:'center',display:'flex',marginBottom:'20px'}}>
            {mobile?<ArrowM/>: <ArrowIcon />}
					  </Grid>
					  <Grid item lg={10.5}  sx={{marginBottom:'20px'}}>
					  <Button  onClick={(e)=>handleClick('step3')} style={{ borderRadius: 12,
    background: '#B1DAEE',
   
    color: 'var(--Brand-Dark-Blue, #053F5C)',
    fontFamily: 'Alegreya',
    fontSize:mobile?'14px': 24,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '64px', // 266.667%
    fontFeatureSettings: "'clig' off, 'liga' off",textTransform:'capitalize',width:mobile?'218px': '386px',
height:mobile?'39px': '64px'
    }}>Reviewing and submitting		  
					  </Button>
					  </Grid>
					  <Grid item lg={12} sx={{marginBottom:'20px'}}>
					  <Button  onClick={(e)=>handleClick('step4')} style={{ borderRadius: 12,
    background: '#053F5C',
   
    color: 'var(--Brand-Dark-Blue, #EFEFEF)',
    fontFamily: 'Alegreya',
    fontSize:mobile?'14px': 24,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '64px', // 266.667%
    fontFeatureSettings: "'clig' off, 'liga' off",textTransform:'capitalize',width:mobile?'218px':'386px',
height:mobile?'39px': '64px'
    }}>
				Checkout Process	  
					  </Button>
					  </Grid>
					  <Grid item lg={12} sx={{marginBottom:'20px'}}>
					  <Button  onClick={(e)=>handleClick('step5')} style={{ borderRadius: 12,
   background: '#053F5C',
   
   color: 'var(--Brand-Dark-Blue, #EFEFEF)',
    fontFamily: 'Alegreya',
    fontSize:mobile?'14px': 24,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '64px', // 266.667%
    fontFeatureSettings: "'clig' off, 'liga' off",textTransform:'capitalize',width:mobile?'218px': '386px',
height:mobile?'39px': '64px'
    }}>
				Reimbursement	  
					  </Button>
					  </Grid>
				  </Grid>
				  <Grid container lg={6} sx={{padding:mobile?'23px':''}}>
				  <Typography variant="body1" component="ul">
        <li style={listItemStyle}>Navigate to the book's page where you purchased it or find a section specifically for reviews.</li>
        <li style={listItemStyle}>Look for an option to "Write a Review".</li>
        <li style={listItemStyle}>Rate the book using a star rating system (typically out of 5 stars).</li>
        <li style={listItemStyle}>Preview your review to check for any errors or improvements.</li>
        <li style={listItemStyle}>Once satisfied, click on "Submit" to publish it on the Amazon website.</li>
      </Typography>
				  </Grid>
				  </Grid>
				  <Grid item lg={11} xs={10}>
			  <Typography id='step4' style={{
				  color: 'var(--Brand-Dark-Blue, #030605)',
    fontFamily: 'Alegreya',
    fontSize:mobile?'16px': 24,
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '64px',}}>STEP 4:</Typography>	  
		  </Grid>
				  
				  <Grid container lg={9} xs={12}>
				  <Grid container lg={6} xs={12} sx={{justifyContent:mobile?'center':''}}>
					  <Grid item lg={12} xs={12} sx={{marginBottom:'20px',textAlign:mobile?'center':''}}>
					  <Button  onClick={(e)=>handleClick('step1')} style={{ borderRadius: 12,
    background: '#053F5C',
   
    color: 'var(--Brand-Dark-Blue, #EFEFEF)',
    fontFamily: 'Alegreya',
    fontSize:mobile?'14px': 24,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '64px', // 266.667%
    fontFeatureSettings: "'clig' off, 'liga' off",textTransform:'capitalize',width:mobile?'218px': '386px',
height:mobile?'39px': '64px'
    }}>
				Signing Up/ Logging In		  
					  </Button>
					  </Grid>
					  <Grid item lg={12} xs={12} sx={{marginBottom:'20px',textAlign:mobile?'center':''}}>
					  <Button   onClick={(e)=>handleClick('step2')}  style={{ borderRadius: 12,
    background: '#053F5C',
   
    color: 'var(--Brand-Dark-Blue, #EFEFEF)',
    fontFamily: 'Alegreya',
    fontSize:mobile?'14px': 24,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '64px', // 266.667%
    fontFeatureSettings: "'clig' off, 'liga' off",textTransform:'capitalize',width:mobile?'218px': '386px',
height:mobile?'39px': '64px'
    }}>
				Browsing and selecting Books	  
					  </Button>
					  </Grid>
					  <Grid item lg={12} xs={12} sx={{marginBottom:'20px',textAlign:mobile?'center':''}}>
					  <Button  onClick={(e)=>handleClick('step3')} style={{ borderRadius: 12,
   background: '#053F5C',
   
   color: 'var(--Brand-Dark-Blue, #EFEFEF)',
    fontFamily: 'Alegreya',
    fontSize:mobile?'14px': 24,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '64px', // 266.667%
    fontFeatureSettings: "'clig' off, 'liga' off",textTransform:'capitalize',width:mobile?'218px': '386px',
height: mobile?'39px':'64px'
    }}>Reviewing and submitting		  
					  </Button>
					  </Grid>
					  <Grid item lg={1.5} xs={1.5} style={{alignItems:'center',display:'flex',marginBottom:'20px'}}>
            {mobile?<ArrowM/>: <ArrowIcon />}
					  </Grid>
					  <Grid item lg={10.5}  sx={{marginBottom:'20px'}}>
					  <Button  onClick={(e)=>handleClick('step4')} style={{ borderRadius: 12,
    background: '#B1DAEE',
   
    color: 'var(--Brand-Dark-Blue, #053F5C)',
    fontFamily: 'Alegreya',
    fontSize:mobile?'14px': 24,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '64px', // 266.667%
    fontFeatureSettings: "'clig' off, 'liga' off",textTransform:'capitalize',width:mobile?'218px': '386px',
height:mobile?'39px': '64px'
    }}>
				Checkout Process	  
					  </Button>
					  </Grid>
					  <Grid item lg={12} sx={{marginBottom:'20px'}}>
					  <Button  onClick={(e)=>handleClick('step5')} style={{ borderRadius: 12,
  background: '#053F5C',
   
  color: 'var(--Brand-Dark-Blue, #EFEFEF)',
    fontFamily: 'Alegreya',
    fontSize:mobile?'14px': 24,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '64px', // 266.667%
    fontFeatureSettings: "'clig' off, 'liga' off",textTransform:'capitalize',width: mobile?'218px':'386px',
height:mobile?'39px': '64px'
    }}>
				Reimbursement	  
					  </Button>
					  </Grid>
				  </Grid>
				  <Grid container lg={6} sx={{padding:mobile?'23px':''}}>
				  <Typography variant="body1" component="ul">
        <li style={listItemStyle}>After submitting your review on Amazon, wait for it to be approved.</li>
        <li style={listItemStyle}>Once your review is live, take a screenshot of the review on Amazon.</li>
        <li style={listItemStyle}>Navigate back to reviewmybooks.com where you will go to the "My List" page.</li>
        <li style={listItemStyle}>Look for the "Get Payment" button provided for every book and fill out the required fields in the submission form.</li>
        <li style={listItemStyle}>Submit the form, and the website’s team will verify the submission, ensuring that your review meets their guidelines and is indeed live on Amazon.</li>
      </Typography>
					  </Grid>
					  </Grid>
					   
				  <Grid item lg={11} xs={10}>
			  <Typography id='step5' style={{
				  color: 'var(--Brand-Dark-Blue, #030605)',
    fontFamily: 'Alegreya',
    fontSize:mobile?'16px': 24,
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '64px',}}>STEP 5:</Typography>	  
				  </Grid>	
				 <Grid container lg={9} xs={12} sx={{marginBottom:mobile?'0px':'100px'}}>
				  <Grid container lg={6} xs={12} sx={{justifyContent:mobile?'center':''}}>
					  <Grid item lg={12} xs={12} sx={{marginBottom:'20px',textAlign:mobile?'center':''}}>
					  <Button  onClick={(e)=>handleClick('step1')} style={{ borderRadius: 12,
  background: '#053F5C',
   
  color: 'var(--Brand-Dark-Blue, #EFEFEF)',
    fontFamily: 'Alegreya',
    fontSize:mobile?'14px': 24,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '64px', // 266.667%
    fontFeatureSettings: "'clig' off, 'liga' off",textTransform:'capitalize',width:mobile?'218px': '386px',
height:mobile?'39px': '64px'
    }}>
				Signing Up/ Logging In		  
					  </Button>
					  </Grid>
					  <Grid item lg={12} xs={12} sx={{marginBottom:'20px',textAlign:mobile?'center':''}}>
					  <Button   onClick={(e)=>handleClick('step2')}  style={{ borderRadius: 12,
    background: '#053F5C',
   
    color: 'var(--Brand-Dark-Blue, #EFEFEF)',
    fontFamily: 'Alegreya',
    fontSize:mobile?'14px': 24,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '64px', // 266.667%
    fontFeatureSettings: "'clig' off, 'liga' off",textTransform:'capitalize',width: mobile?'218px':'386px',
height:mobile?'39px': '64px'
    }}>
				Browsing and selecting Books	  
					  </Button>
					  </Grid>
					  <Grid item lg={12} xs={12} sx={{marginBottom:'20px',textAlign:mobile?'center':''}}>
					  <Button  onClick={(e)=>handleClick('step3')} style={{ borderRadius: 12,
    background: '#053F5C',
   
    color: 'var(--Brand-Dark-Blue, #EFEFEF)',
    fontFamily: 'Alegreya',
    fontSize: mobile?'14px':24,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '64px', // 266.667%
    fontFeatureSettings: "'clig' off, 'liga' off",textTransform:'capitalize',width:mobile?'218px': '386px',
height:mobile?'39px': '64px'
    }}>Reviewing and submitting		  
					  </Button>
					  </Grid>
					  <Grid item lg={12}  xs={12} sx={{marginBottom:'20px',textAlign:mobile?'center':''}}>
					  <Button  onClick={(e)=>handleClick('step4')} style={{ borderRadius: 12,
    background: '#053F5C',
   
    color: 'var(--Brand-Dark-Blue, #EFEFEF)',
    fontFamily: 'Alegreya',
    fontSize:mobile?'14px': 24,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '64px', // 266.667%
    fontFeatureSettings: "'clig' off, 'liga' off",textTransform:'capitalize',width:mobile?'218px': '386px',
height:mobile?'39px': '64px'
    }}>
				Checkout Process	  
					  </Button>
					  </Grid>
					  <Grid item lg={1.5} xs={1.5} style={{alignItems:'center',display:'flex',marginBottom:'20px'}}>
            {mobile?<ArrowM/>: <ArrowIcon />}
					  </Grid>
					  <Grid item lg={10.5}  sx={{marginBottom:'20px'}}>
					  <Button  onClick={(e)=>handleClick('step5')} style={{ borderRadius: 12,
    background: '#B1DAEE',
   
    color: 'var(--Brand-Dark-Blue, #053F5C)',
    fontFamily: 'Alegreya',
    fontSize:mobile?'14px': 24,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '64px', // 266.667%
    fontFeatureSettings: "'clig' off, 'liga' off",textTransform:'capitalize',width: mobile?'218px':'386px',
height:mobile?'39px': '64px'
    }}>
				Reimbursement	  
					  </Button>
					  </Grid>
				  </Grid>
				  <Grid container lg={6} sx={{padding:mobile?'23px':'',marginBottom:mobile?'50px':''}}>
				  <Typography variant="body1" component="ul">
        <li style={listItemStyle}>Once your submission is verified and approved, you will receive your reimbursement for the book purchase and review incentive as well.</li>
        <li style={listItemStyle}>This could be in the form of a refund to your original payment method.</li>
        <li style={listItemStyle}>Provide feedback on the process if prompted, helping the website improve its user experience.</li>
        <li style={listItemStyle}>Consider engaging further with the community by participating in discussions or leaving more reviews.</li>
      </Typography>
				  </Grid>
			  </Grid>
			  </Grid>
			  
		  <Footer/>
	  </>
  )
}

export default UserJourney