import React from 'react';

const ArrowIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      style={{
        width: '46px',
        height: '46px',
        transform: 'rotate(0deg)',
        flexShrink: 0,
      }}
    >
      <path
        d="M9.58326 5.7498L9.58325 40.2498C9.58435 40.5991 9.68069 40.9415 9.86189 41.2401C10.0431 41.5387 10.3023 41.7823 10.6116 41.9445C10.921 42.1068 11.2687 42.1816 11.6174 42.1609C11.9661 42.1402 12.3025 42.0249 12.5905 41.8272L37.5072 24.5772C38.5403 23.8623 38.5403 22.1411 37.5072 21.4243L12.5905 4.1743C12.3031 3.97463 11.9665 3.85754 11.6172 3.83575C11.268 3.81396 10.9194 3.8883 10.6094 4.0507C10.2994 4.2131 10.0399 4.45734 9.85898 4.75689C9.67806 5.05645 9.5827 5.39985 9.58326 5.7498Z"
        fill="#5A9DBF"
      />
    </svg>
  );
};

export default ArrowIcon;
