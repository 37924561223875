import React from 'react'
import { Grid,Box, Typography, useMediaQuery } from '@mui/material'
import Im from './Images/Work.json'
import Lottie from 'react-lottie';

const Work = () => {
  const mobile = useMediaQuery('(max-width:600px)');
    const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: Im,
		rendererSettings: {
		  preserveAspectRatio: "xMidYMid slice"
		}
	};
  return (
    <>
   <Grid container lg={12} xs={12} sx={{background:'var(--orange-grad, linear-gradient(180deg, #FFF 0%, #F27F0C 346.94%))'}}>
    <Grid container lg={10} xs={12} sx={{margin:mobile?'0':"auto",padding:mobile?'24px':'0px',marginBottom:'150px'}}>
<Grid container lg={12} xs={12}>
  <Grid item lg={12} xs={8}>
    <Typography sx={{textAlign:'center',marginTop:'56px',marginBottom:'56px',fontSize:mobile?'26px':'56px',fontWeight:'500',lineHeight:'64px',fontFamily:'Alegreya',color:'#053F5C'}}>How RMB Works</Typography>
  </Grid>
</Grid>
    <Grid container lg={3.8} xs={12} sx={{backgroundColor:'#F8F5F0',borderRadius:mobile?'26px':'60px',justifyContent:'left',marginBottom:mobile?'30px':'0px',marginTop:mobile?'-40px':''}}>
      <Grid container lg={10} xs={12}>
      <Grid item lg={12} xs={12} sx={{padding:'0px 0px 0px 20px',marginTop:mobile?'-35px':'0px',marginBottom:mobile?'28px':'0px'}}>
    <Typography sx={{fontSize:mobile?'20px':'32px',fontWeight:'500',lineHeight:'64px',fontFamily:'Alegreya',marginTop:'30px',color:'#030605'}}>For Authors</Typography>
</Grid>
<Grid item lg={12} xs={12} sx={{padding:'0px 0px 0px 0px',marginTop:mobile?'-42px':'0px',marginBottom:mobile?'23px':'0px'}}>
    <Typography sx={{fontSize:mobile?'14px':'22px',fontWeight:'400',lineHeight:mobile?'21px':'36px',fontFamily:'Alegreya',color:'#000'}}>
        <ul>
            <li>Getting Reviews to Increase the Visibility of Your Book</li>
            <li>Authentic Reviews that Attract More Readers</li>
            <li>Seamless Process</li>
            <li>Multiple Packages to Choose From</li>
        </ul>
    </Typography>
</Grid>

</Grid>
    </Grid>
    {mobile?'':<Grid container lg={4} sx={{justifyContent:'center',alignItems:'center'}} >
        <Box style={{borderRadius:'36px',backgroundColor:'#FCF5E2',marginTop:'-96px'}}>   <Lottie height={180} width={217} options={defaultOptions} style={{borderRadius:'36px'}}/></Box>
 
        </Grid>}
        <Grid container lg={3.8} xs={12} sx={{backgroundColor:'#F8F5F0',borderRadius:mobile?'26px':'60px',justifyContent:'left', paddingBottom: '20px',marginBottom:'0px'}}>
    <Grid container lg={11} xs={12}>
        <Grid item lg={12} xs={12} sx={{padding:'0px 0px 0px 30px',marginBottom:mobile?'60px':'0px'}}>
            <Typography sx={{fontSize:mobile?'20px':'32px',fontWeight:'500',lineHeight:'64px',fontFamily:'Alegreya',marginTop:mobile?'10px':'30px',color:'#030605'}}>For Reviewers</Typography>
        </Grid>
        <Grid item lg={12} xs={12} sx={{padding:'0px 0px 0px 12px',marginTop:'-80px'}}>
            <Typography sx={{fontSize:mobile?'14px':'22px',fontWeight:'400',lineHeight:mobile?'21px':'36px',fontFamily:'Alegreya',color:'#000'}}>
                <ul>
                    <li>Free Books</li>
                    <li>Get Paid for Each Review</li>
                    <li>Quick and Easy Payment</li>
                    <li>Seamless Process</li>
                </ul>
            </Typography>
        </Grid>
    </Grid>
</Grid>



    </Grid>
   </Grid>
    </>
  )
}

export default Work