import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {Typography,Grid,Button,useMediaQuery} from '@mui/material';
import { jwtDecode } from 'jwt-decode';
import Header from './Header';
import { Api_url } from './helper';
import { Footer } from './Footer';

const MyList = () => {
	const [books, setBooks] = useState([]);
 
  const [error, setError] = useState(null);
  const token = localStorage.getItem('token')
  const userId = jwtDecode(token);
  const userId1=userId._id
  const mobile = useMediaQuery('(max-width:600px)');
  useEffect(() => {
    const fetchUserBooks = async () => {
      try {
        const response = await axios.get(`${Api_url}/sign/users/${userId1}/books`);
        setBooks(response.data);
      //console.log(response.data)
      } catch (error) {
        setError(error.message || 'Internal server error');
     
      }
    };

    fetchUserBooks();
  }, []);
	const handlePayment = (hello,bookname) => {
		//console.log('yes',hello)
    window.location.href = `/payment?bookname=${bookname}&bookid=${hello}`;
	}

 const amazonlink=(book)=>{
  window.open(book, '_blank');
 }

  return (
	  <div>
      <Header />
      <Grid container lg={12} xs={12} style={{ background: '#F6C35E' }}>
        <Grid item lg={12} xs={11} sx={{marginTop:mobile?'20px':""}}>
          <Typography sx={{ fontSize: mobile?"26px":'56px', fontWeight: '700', color: '#053F5C', fontFamily: 'Alegreya', textAlign: 'center' ,marginTop:'70px',marginBottom:'30px'}}>My Lists</Typography>
        </Grid>

        {books.length > 0 ? (
          <Grid container lg={12} justifyContent="center">
            {books.map((item, index) => (
              <Grid item lg={8} xs={9} key={index} style={{ borderRadius: '24px', background: '#EFEFEF', margin: '24px auto', alignItems: 'center', padding: '20px' }}>
                <Grid container spacing={2}>
				<Grid item lg={2}>
  {item.book && item.book.coverImagePath &&
    <img src={item.book.coverImagePath} alt={item.book.title} style={{ width: '100%', height: 'auto', objectFit: 'cover', borderRadius: '12px' }} />
  }
</Grid>

                  <Grid item lg={10}>
				  {item && item.book && 

      <Typography variant="h5" style={{ textAlign: 'start', color: '#1B1D1C', fontFamily: 'Alegreya' }}>
        {item.book.title}
      </Typography>
     
  }
             {item && item.book && item.book.Aboutb && 
    <Typography variant="body1" style={{ textAlign: 'start', fontFamily: 'Alegreya' }}>
      {item.book.Aboutb}
    </Typography>
							}	{item.status === 'pending' ?
								<Grid item lg={12}  xs={12}  sx={{  justifyContent: mobile ? "center" : 'end', display: 'flex', flexDirection: mobile ? 'column' : 'row', alignItems: mobile ? 'center' : 'flex-start' ,
    }}
>
    <Button
        onClick={() => amazonlink(item.book.Amazon)}
        sx={{
            color: '#053F5C',
            border: '2px solid #053F5C',
            textTransform: 'none',
            width: mobile ? '250px' : "auto",
            borderRadius: '6px',
            background: 'transparent',
            fontFamily: 'Alegreya',
            height: mobile ? '40px' : '42px',
            marginTop: mobile ? '' : '12px',
            marginBottom: mobile ? '10px' : '0', // Margin bottom for spacing in mobile view
            '&:hover': {
                background: '#fff',
            },
        }}
        variant="contained"
    >
        Amazon link
    </Button>
    <Button
        onClick={() => handlePayment(item.book._id, item.book.title)}
        sx={{
            marginTop: mobile ? '10px' : '12px',
            marginLeft: mobile ? '0' : '10px', 
            textDecoration: 'none',
            textTransform: 'none',
            background: '#053F5C',
            fontFamily: 'Alegreya',
            fontSize: "14px",
            fontWeight: '700',
            height: mobile ? '40px' : '42px',
            width: mobile ? '100%' : "auto",
            '&:hover': {
              background: '#053F5C',
          },
        }}
        variant="contained"
        color="primary"
    >
        Get Payment
    </Button>
</Grid>
: (
    <Typography style={{color:'#053F5C',fontSize:"14px",fontWeight:700,textAlign:mobile?'center':'end'}}>
      {item.status === 'waiting' && 'Waiting for Approval'}
      {item.status === 'accepted' && 'Approved'}
      {item.status === 'rejected' && 'Rejected'}
    </Typography>
  )}

                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Grid item lg={12} style={{ textAlign: 'center',minHeight:'100vh' }}>
            <Typography variant="body1">No books found for this user</Typography>
          </Grid>
        )}
		  </Grid>
		  <Footer/>
    </div>
  );
}

export default MyList