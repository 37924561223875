import React from 'react'
import { Grid,Typography,Button } from '@mui/material'
import Image from 'mui-image'
import Im from './Images/HeroBook.json'
import Im1 from './Images/Pen.json'
import Lottie from 'react-lottie';
import { Link } from 'react-router-dom'
import {useMediaQuery} from '@mui/material'

// import '@fontsource/alegreya'
const Nhero = () => {
    const mobile = useMediaQuery('(max-width:600px)');
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: Im,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      },
    };
    const defaultOptions1 = {
		loop: true,
		autoplay: true,
		animationData: Im1,
    margin:'0px 0px 0px 0px',
		rendererSettings: {
		  preserveAspectRatio: "xMidYMid slice"
		}
    
	};
  
  return (
    <>
  <Grid container lg={12} xs={12} >
<Grid container lg={10} xs={12} sx={{margin:mobile?'0':'auto'}}>
<Grid container lg={12} xs={12} sx={{marginTop:mobile?'0':'90px'}} >
        <Grid container lg={7.7} xs={12} sx={{alignItems:'center',padding:mobile?'24px':'0px'}} >
          <Grid container lg={12} xs={12}>
          <Grid item lg={3} sx={{display:'flex',alignItems:'flex-end'}}>
            <Typography sx={{fontSize:mobile?'14px':'24px',fontWeight:mobile?'700':'500',lineHeight:'32px',color:'#F27F0C',fontFamily:'Alegreya'}}>reviewmybooks</Typography>
          </Grid>
          <Grid item lg={9}>
         {mobile?'': <Lottie height={100} width={100} options={defaultOptions1} style={{borderRadius:'280px',margin:'-21px'}}/>}
         {mobile?<Lottie height={71} width={71} options={defaultOptions1} style={{borderRadius:'280px',margin:'0'}}/>: ''}
          </Grid>
          </Grid>
<Grid item lg={12} xs={10}>
    <Typography sx={{fontSize:mobile?'26px':'60px',fontWeight:mobile?'700':'600',lineHeight:mobile?'35px':'normal',color:'#053F5C',fontFamily:'Alegreya'}}>Free reads, Paid Critiques-Double{mobile?<br/>:''} the Perks</Typography>


							  <Typography sx={{ fontSize: mobile ? '14px' : '24px', fontWeight: mobile ? '500' : '400', lineHeight: mobile ? '19px' : '32px', color: '#053F5C', marginTop: '20px', fontFamily: 'Alegreya' }}>Building a community for authors, readers and book reviewers to{mobile?"":<br/>} bring remarkable books to the limelight.</Typography>
    </Grid>
    <Grid container lg={9.5} xs={11}  spacing={2} sx={{display:'flex',marginTop:'30px'}}>
      <Grid item lg={3} xs={6} style={{justifyContent:mobile?"center":'',display:'flex'}}>
        <Link to='/Ausign'>
      <Button 
            sx={{
                fontSize:mobile?'14px':'18px',
                fontWeight:'700',
                lineHeight:'32px',
                backgroundColor:'#053F5C',
                borderRadius:'6px',
                padding:'14px 30px',
                width:'128px',
									  color: '#fff',
									  fontFamily:'alegreya',
                textTransform: 'capitalize',
                height:'42px',
                '&:hover': {
                    backgroundColor:'#053F5C',
                    color:'#fff',
                },
                marginRight: '10px' // Add margin between buttons
            }}
        >
            Author
        </Button>
        </Link>
      </Grid>
      <Grid item lg={3} xs={6}style={{justifyContent:mobile?"center":'',display:'flex'}}>
        <Link to='/sign'>
      <Button 
           sx={{
            fontSize:mobile?'14px':'18px',
            fontWeight:'700',
            lineHeight:'32px',
            backgroundColor:'#053F5C',
            borderRadius:'6px',
            padding:'14px 30px',
            width:'128px',
                                  color: '#fff',
                                  fontFamily:'alegreya',
            textTransform: 'capitalize',
            height:'42px',
            '&:hover': {
                backgroundColor:'#053F5C',
                color:'#fff',
            },
            marginRight: '10px' // Add margin between buttons
        }}
        >
           Reviewer
        </Button>
        </Link>
      </Grid>
    </Grid>


        </Grid>
            <Grid container lg={4.3} sx={{marginTop:mobile?'20px':'0px',marginBottom:mobile?'41px':'0px',marginLeft:mobile?'0':'0px'}}>
           {mobile?'': <Lottie height={399} width={399} options={defaultOptions} style={{borderRadius:'280px'}}/>}
           {mobile?<Lottie height={257} width={257} options={defaultOptions} style={{borderRadius:'280px'}}/>: ''}
            </Grid>
         
            </Grid>
            </Grid>



</Grid>
  
    </>
  )
}

export default Nhero