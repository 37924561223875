import React from 'react';

const ArrowM = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
    <path d="M5.4157 3.24997L5.4157 22.75C5.41632 22.9474 5.47077 23.1409 5.57319 23.3097C5.67561 23.4785 5.82213 23.6161 5.99696 23.7078C6.1718 23.7996 6.36834 23.8418 6.56542 23.8302C6.76251 23.8185 6.95267 23.7533 7.11545 23.6416L21.1988 13.8916C21.7827 13.4875 21.7827 12.5146 21.1988 12.1095L7.11545 2.35947C6.95301 2.24661 6.76275 2.18043 6.56534 2.16812C6.36793 2.1558 6.17092 2.19782 5.99571 2.28961C5.8205 2.3814 5.6738 2.51945 5.57154 2.68876C5.46929 2.85808 5.41539 3.05217 5.4157 3.24997Z" fill="#5A9DBF"/>
  </svg>
  );
};

export default ArrowM;