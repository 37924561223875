import React from 'react'
import { Typography ,Grid} from '@mui/material'
import Header from './Header';
import {useMediaQuery} from '@mui/material';
import { Footer } from './Footer';

function Privacy() {
	const mobile = useMediaQuery('(max-width:600px)');
	const typographytStyle = {
		color: 'var(--Black, #000)',
		textAlign: 'center',
		fontFamily: 'alegreya',
		fontSize: 36,
		fontStyle: 'normal',
		fontWeight: 700,
		lineHeight: '48px',
		marginTop: '36px',
		marginBottom:'32px'// 133.333%
	};
	const typographytmStyle = {
		color: 'var(--Black, #000)',
		textAlign: 'center',
		fontFamily: 'alegreya',
		fontSize: 20,
		fontStyle: 'normal',
		fontWeight: 700,
		lineHeight: '48px',
		marginTop: '36px',
		marginBottom:'32px'// 133.333%
	};
	const typographyhStyle = {
		color: 'var(--Black, #000)',
    fontFamily: 'alegreya',
    fontSize: 26,
		fontStyle: 'normal',
		// marginBottom: '36px',
		marginTop:'12px',
    fontWeight: 700,
    lineHeight: '156.023%', // 40.566px
    fontFeatureSettings: "'clig' off, 'liga' off", // 133.333%
	};
	const typographysubStyle = {
		color: 'var(--Black, #000)',
		fontFamily: 'alegreya',
		fontSize: 20,
		fontStyle: 'normal',
		fontWeight: 400,
		lineHeight: '156.023%', // 31.205px
		fontFeatureSettings: "'clig' off, 'liga' off", // 133.333%
		marginBottom: '36px',
		marginTop:'12px'
	};
	const typographymsubStyle = {
		color: 'var(--Black, #000)',
    fontFamily: 'alegreya',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '156.023%', // 21.843px
    fontFeatureSettings: "'clig' off, 'liga' off",
	}
	const typographymhStyle = {
		color: 'var(--Black, #000)',
		fontFamily: 'alegreya',
		fontSize: 16,
		fontStyle: 'normal',
		fontWeight: 700,
		lineHeight: 'normal',
		marginBottom: '36px',
		marginTop:'12px'
	}
  return (
	<>
		  <Header/>
		  <Grid container lg={12} xs={10} style={{justifyContent:'center'}}>
			  <Grid container lg={10}  xs={10}>
			  <Typography style={mobile?typographytmStyle:typographytStyle}>
			  Privacy Policy
				  </Typography> 
				  <Grid item lg={12}>
				  <Typography style={mobile?typographymhStyle:typographyhStyle}>
				  Collection of Personal Information:
				  </Typography>  
				  </Grid>
				  <Grid item lg={12}>
				  <Typography style={mobile?typographymsubStyle:typographysubStyle}>
						  “We may collect personal information, including but not limited to your name, email address, and location when you subscribe to our newsletter, register for an account, or interact with our website.”
					  </Typography>  
				  </Grid>

				  <Grid item lg={12}>
				  <Typography style={mobile?typographymhStyle:typographyhStyle}>
				  Use of Personal Information:
				  </Typography>  
				  </Grid>
				  <Grid item lg={12}>
				  <Typography style={mobile?typographymsubStyle:typographysubStyle}>
						  “Your personal information may be used to personalize your experience on our website, to send periodic emails regarding our content, or to improve our website based on the information and feedback we receive from you.”
					  </Typography>  
				  </Grid>


				  <Grid item lg={12}>
				  <Typography style={mobile?typographymhStyle:typographyhStyle}>
				  Third-Party Services:
				  </Typography>  
				  </Grid>
				  <Grid item lg={12}>
				  <Typography style={mobile?typographymsubStyle:typographysubStyle}>
						  “We may use third-party services, such as analytics tools or payment processors, that may collect, use, and share your information. Please review the privacy policies of these third-party services for more information.”
					  </Typography>  
				  </Grid>


				  <Grid item lg={12}>
				  <Typography style={mobile?typographymhStyle:typographyhStyle}>
				  Cookies and Tracking Technologies:
				  </Typography>  
				  </Grid>
				  <Grid item lg={12}>
				  <Typography style={mobile?typographymsubStyle:typographysubStyle}>
						  “We use cookies and similar tracking technologies to enhance your browsing experience on our website. You can choose to disable cookies through your browser settings, but this may affect your ability to access certain features of the site.”
					  </Typography>  
				  </Grid>


				  <Grid item lg={12}>
				  <Typography style={mobile?typographymhStyle:typographyhStyle}>
				  Security Measures:
				  </Typography>  
				  </Grid>
				  <Grid item lg={12}>
				  <Typography style={mobile?typographymsubStyle:typographysubStyle}>
						  “We implement reasonable security measures to protect the confidentiality and integrity of your personal information. However, we cannot guarantee the security of information transmitted over the internet.”
					  </Typography>  
				  </Grid>


				  <Grid item lg={12}>
				  <Typography style={mobile?typographymhStyle:typographyhStyle}>
				  Updates to the Privacy Policy:
				  </Typography>  
				  </Grid>
				  <Grid item lg={12}>
				  <Typography style={mobile?typographymsubStyle:typographysubStyle}>
						  “We reserve the right to update this privacy policy at any time. Changes will be effective immediately upon posting. It is your responsibility to review this policy periodically to stay informed about how we are protecting and using your personal information.”
					  </Typography>  
				  </Grid>

				 
			  </Grid>
		  </Grid>
		  <Footer/>
		  </>
  )
}
export default Privacy
